import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';
import {
  OverflowThumb,
  DisableNoneLayer,
} from 'static/styles/jss/abstracts/extends';

const useLabelStyles = makeStyles(() => ({
  root: {
    lineHeight: 'inherit',
    transform: 'translate(0px) !important',
    position: 'relative',
    fontSize: variables.$input.$fontSize,
    fontWeight: '600',
    paddingBottom: '7px',
    marginBottom: '0',
    color: props => props.color,
  },
  disabled: {
    '&:not(.Mui-error)': {
      color: props => [props.color, '!important'],
    },
  },
  error: {
    color: `${variables.$danger}!important`,
    '& + .MuiInputBase-root': {
      ...Shadow(`0 0 1px 1px ${variables.$danger} inset !important`),
    },
  },
}));

const useFormControlStyles = makeStyles(() => ({
  root: {
    userSelect: 'none',
    width: props => props.width,
    display: 'flex',
    '& .MuiInput-formControl.Mui-error': {
      boxShadow: `0 0 1px 1px ${variables.$danger} inset !important`,
    },
    '& label': {
      fontSize: props => variables.$input[`$${props.size}`].$fontSize,
    },
    '& .MuiSelect-root, & .MuiAutocomplete-input': {
      padding: props =>
        `${variables.$input[`$${props.size}`].$padding}!important`,
      fontSize: props => `${variables.$input[`$${props.size}`].$fontSize}px`,
      height: props => variables.$fieldsSizes[`$${props.size}`],
      lineHeight: props => variables.$fieldsSizes[`$${props.size}`],
    },
    '&.disable-select-wrapper': {
      '& .MuiAutocomplete-root': {
        ...DisableNoneLayer,
      },
    },
    // input Mask
    '& .mask-input': {
      position: 'relative',
      '&[data-disabled="true"]': {
        '& .MuiInputBase-root': {
          ...DisableNoneLayer,
        },
      },
      '& > .MuiTextField-root': {
        width: '100%',
        position: 'relative',
        zIndex: '22',
        '& .MuiInputBase-root': {
          transition: props => props.transition,
          marginBottom: props => props.marginBottom,
          boxShadow: props => props.boxShadow,
          color: props => props.valueColor,
          borderRadius: props =>
            props.isOpened
              ? `${variables.$input.$radius} ${variables.$input.$radius} 0 0`
              : variables.$input.$radius,
          backgroundColor: props =>
            `${props.backgroundColor || '#fff'}!important`,
          zIndex: '22',
          '& input::-webkit-input-placeholder': {
            color: props => props.placeholderColor,
            opacity: '1',
          },
          '&::before': {
            display: 'none',
          },
          padding: '0 !important',
          '&:hover': {
            ...variables.$input.$hover,
          },
          '& input': {
            padding: props => variables.$input[`$${props.size}`].$padding,
            fontSize: props =>
              `${variables.$input[`$${props.size}`].$fontSize}px`,
            height: props => variables.$fieldsSizes[`$${props.size}`],
            lineHeight: props => variables.$fieldsSizes[`$${props.size}`],
            paddingLeft: '40px !important',
            borderRadius: 'inherit',
            zIndex: '222',
            '&:focus': {
              ...variables.$input.$focus,
            },
          },
          '& .MuiInputAdornment-root': {
            '&.MuiInputAdornment-positionStart': {
              left: '12px',
            },
            margin: 0,
            position: 'absolute',
            '& .icon-Time': {
              color: variables.$datepicker.$iconColor,
              fontSize: props =>
                variables.$datepicker[`$${props.size}`].$iconSize,
            },
            '&.MuiInputAdornment-positionEnd': {
              height: 'auto',
              margin: 'auto',
              top: '0px',
              right: '0px',
              bottom: '0px',
              position: 'absolute',
              paddingRight: '4px',
              zIndex: '223',
              '& .arrows-slide-datepicker': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                  width: '30px',
                  cursor: 'pointer',
                  fill: props => props.iconColor,
                  '&.left-icon': {
                    transform: 'rotate(90deg)',
                  },
                  '&.right-icon': {
                    transform: 'rotate(-90deg)',
                  },
                  '&.disable-btn': {
                    cursor: 'not-allowed',
                    opacity: '0.5',
                  },
                },
              },
            },
          },
        },
      },
      '&.focused-input': {
        '& .MuiInputBase-root': {
          ...Shadow('none !important'),
        },
      },
      '& .MuiAutocomplete-root': {
        position: 'absolute',
        opacity: '0',
        width: '100%',
        visibility: 'hidden',
        top: '0',
        margin: '0 !important',
      },
    },
  },
}));

const usePopoverStyles = makeStyles(() => ({
  root: {
    zIndex: props => props.zIndex || '99999 !important',
  },
  paper: {
    transitionDuration: '0s !important',
    backgroundColor: props => props.backgroundColor,
    borderRadius: `0 0 ${variables.$input.$radius} ${variables.$input.$radius}`,
    border: props =>
      props.isDark
        ? `1px solid ${variables.$select.$dark.$borderColor}`
        : 'none',
    marginLeft: props => props.marginLeft,
    ...Shadow('0px 2px 2.5px 0px rgb(0 0 0 / 45%)'),
    marginTop: props => props.paperMarginTop,
    '& .MuiList-root': {
      ...OverflowThumb,
      '& > li': {
        color: props => props.listItemColor,
        fontSize: props => variables.$input[`$${props.size}`].$fontSize,
        padding: props =>
          variables.$select.$dropdown.$listItem[`$${props.size}`].$padding,
        '& .MuiListItemText-root': {
          margin: props =>
            variables.$select.$dropdown.$listItem[`$${props.size}`].$margin,
        },
        '&.Mui-selected': {
          color: props => props.selectedItemColor,
          backgroundColor: props => props.selectedItemBG,
          fontWeight: 'bold',
          '& .MuiListItemIcon-root': {
            color: 'inherit',
            '& span.icon': {
              color: 'inherit',
            },
          },
        },
      },
    },
  },
}));

const useSelectStyles = makeStyles(() => ({
  select: {
    '&:before, &:after': {
      display: 'none',
    },
    '& > .MuiSelect-root': {
      '& > div': {
        overflow: 'hidden',
        width: '95%',
      },
    },
    width: '100%',
    marginTop: '0px !important',
    transition: props => props.transition,
    lineHeight: 'inherit !important',
    marginBottom: props => props.marginBottom,
    boxShadow: props =>
      props.isOpened ? '0px 1px 2px 0px rgb(0 0 0 / 45%)' : props.boxShadow,
    borderRadius: props =>
      props.isOpened
        ? `${variables.$input.$radius} ${variables.$input.$radius} 0 0`
        : variables.$input.$radius,
    backgroundColor: props => props.backgroundColor || '#fff',
    zIndex: '22',
    '&:not(.Mui-disabled):hover': {
      ...variables.$input.$hover,
    },
    '&.Mui-disabled': {
      '& .select-custom-icon, & .MuiInputBase-input.Mui-disabled': {
        color: `${variables.$input.$readOnlyColor} !important`,
      },
    },
    '& .select-custom-icon': {
      fontSize: `${variables.$input.$iconSize}px`,
      top: 0,
      bottom: 0,
      margin: 'auto',
      right: '7px',
      position: 'absolute',
      pointerEvents: 'none',
      width: '1em',
      height: '1em',
      display: 'inline-block',
      flexShrink: '0',
      userSelect: 'none',
    },
  },
  root: {
    minHeight: 'auto',
    padding: variables.$input.$padding,
    fontSize: variables.$input.$fontSize,
    boxShadow: props => props.boxShadow,
    borderRadius: 'inherit !important',
    transition: '.4s',
    backgroundColor: 'transparent !important',
    color: props => props.textColor,
    '& .with-avatar-value-wrapper': {
      paddingLeft: '40px',
      position: 'relative',
      '& > img': {
        maxWidth: '30px',
        maxHeight: '25px',
        objectFit: 'cover',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        margin: 'auto',
      },
    },
    '&[aria-expanded="true"]': {
      ...variables.$input.$focus,
    },
    '& .default-value-empty': {
      color: props => props.placeholderColor,
    },
  },
  icon: {
    fill: props => props.iconColor,
    fontSize: '30px',
    top: 'calc(50% - 15px)',
    right: '4px',
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

const useAutoCompleteStyles = makeStyles(() => ({
  root: {
    marginBottom: props => props.marginBottom,
    zIndex: '22',
    '&.Mui-focused .MuiInputBase-root': {
      ...variables.$input.$focus,
    },
    '&.MuiMultiple_Autocomplete_field .MuiFilledInput-root': {
      // todo need to discuss about multiple prop
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      '&::-webkit-scrollbar-thumb': {
        width: '0px',
        background: 'none',
        height: '0px',
      },
      '&::-webkit-scrollbar': {
        background: 'none',
        height: '0px',
        width: '0px',
      },
    },
  },
  popupIndicator: {
    padding: '0',
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiIconButton-label': {
      '& svg': {
        fill: props => props.iconColor,
        fontSize: '30px',
      },
    },
  },
  endAdornment: {
    bottom: '0',
    top: '0',
    margin: 'auto',
    height: '30px',
    width: '30px',
    right: '4px !important',
  },
  inputRoot: {
    boxShadow: props => props.boxShadow,
    transition: '.4s',
    color: props => props.textColor,
    borderRadius: props =>
      props.isOpened
        ? `${variables.$input.$radius} ${variables.$input.$radius} 0 0`
        : variables.$input.$radius,
    backgroundColor: props => `${props.backgroundColor || '#fff'}!important`,
    '& input::-webkit-input-placeholder': {
      color: props => props.placeholderColor,
      opacity: '1',
    },
    '&::before': {
      display: 'none',
    },
    padding: '0 !important',
    '&:hover': {
      ...variables.$input.$hover,
    },
  },
  input: {
    padding: `${variables.$input.$padding}!important`,
    fontSize: variables.$input.$fontSize,
    height: 'auto',
  },
  clearIndicator: {
    display: 'none',
  },
  popper: {
    border: props =>
      props.isDark
        ? `1px solid ${variables.$select.$dark.$borderColor}`
        : 'none',
    ...Shadow('0px 2px 2.5px 0px rgb(0 0 0 / 45%)'),
    borderRadius: `0 0 ${variables.$input.$radius} ${variables.$input.$radius}`,
    marginTop: props => props.paperMarginTop,
    boxSizing: 'content-box',
  },
  paper: {
    margin: '0',
    ...Shadow('none'),
    backgroundColor: props => `${props.backgroundColor || '#fff'}!important`,
    borderRadius: '0',
    '& .MuiAutocomplete-noOptions': {
      color: props => props.listItemColor,
      fontSize: props => variables.$input[`$${props.size}`].$fontSize,
      padding: props => variables.$input[`$${props.size}`].$padding,
    },
  },
  listbox: {
    ...OverflowThumb,
    '& > li': {
      transition: '.2s',
      color: props => props.listItemColor,
      fontSize: props => variables.$listBox[`$${props.size}`].$fontSize,
      padding: props => variables.$listBox[`$${props.size}`].$padding,
      letterSpacing: '0.7px',
      '&.MuiAutocomplete-option:active, &.MuiAutocomplete-option[aria-selected="true"]':
        {
          color: props => props.selectedItemColor,
          backgroundColor: props => props.selectedItemBG,
          textShadow: '.5px 0px 0px #fff, 0px 0px 0px #fff, 0px 0px 0px #fff',
          letterSpacing: '0.7px',
        },
    },
  },
}));

const useItemStyles = makeStyles(() => ({
  root: {
    '& .MuiListItemIcon-root': {
      overflow: 'hidden',
      minWidth: '0',
      width: props =>
        variables.$select.$dropdown.$listItem[`$${props.size}`].$size,
      height: props =>
        variables.$select.$dropdown.$listItem[`$${props.size}`].$size,
      borderRadius: '50%',
      marginRight: props =>
        variables.$select.$dropdown.$listItem[`$${props.size}`].$iconRight,
      overflow: 'hidden',
      '& span.icon': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props => props.iconColor,
        fontSize: props =>
          variables.$select.$dropdown.$listItem[`$${props.size}`].$iconSize,
      },
    },
    '& .MuiListItemText-root': {
      margin: '0',
      overflow: 'hidden',
      '& .MuiListItemText-primary': {
        fontSize: 'inherit',
      },
    },
  },
}));

const usePickerPaperStyles = makeStyles(() => ({
  root: {
    '&.datePicker': {
      '& .MuiPickersCalendarHeader-switchHeader .MuiPickersSlideTransition-transitionContainer > .MuiTypography-root':
        {
          textTransform: 'capitalize',
        },
    },
    '&.mobile-mode_datePicker': {
      backgroundColor: props =>
        props.isDark ? 'rgb(255 243 243 / 30%)' : 'rgba(0,0,0, .5)',
      '& .MuiPopover-paper': {
        left: '0px !important',
        right: '0px !important',
        top: '0px !important',
        bottom: '0px !important',
        width: 'fit-content !important',
        height: 'fit-content !important',
        margin: 'auto',
      },
    },
    '& .MuiPopover-paper': {
      transitionDuration: '0s !important',
      marginTop: '5px',
      backgroundColor: props => props.backgroundColor,
      border: props => (props.isDark ? '1px solid #11509F' : 'none'),
      ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5)'),
      '& .MuiPickersCalendarHeader-daysHeader': {
        '& .MuiTypography-root': {
          color: props => props.weekColor,
        },
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiTypography-root': {
          color: props => props.valueColor,
        },
      },
      '& .MuiButtonBase-root': {
        backgroundColor: props => props.backgroundColor,
        color: props => props.valueColor,
        '&.MuiPickersDay-daySelected': {
          backgroundColor: variables.$datepicker.$actionsColor,
          color: '#fff !important',
          '&.MuiPickersDay-dayDisabled': {
            color: '#b4b4b4 !important',
          },
        },
        '&.MuiPickersDay-dayDisabled': {
          color: props => [props.weekColor, '!important'],
        },
      },
    },
    '& .datepicker-actions-wrap': {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '20px',
      '& button': {
        background: 'none',
        border: 'none',
        fontWeight: '900',
        color: props => props.actionsColor,
        fontSize: '13px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        outline: 'none',
        '&.disable-select': {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      },
    },
  },
}));

const useDatePickerStyles = makeStyles(() => ({
  root: {
    margin: '0',
    '& .MuiInputBase-root': {
      '&:before, &:after': {
        display: 'none',
      },
      '&:hover': {
        ...variables.$input.$hover,
      },
      width: '100%',
      marginTop: '0px !important',
      transition: props => props.transition,
      padding: '0',
      marginBottom: props => props.marginBottom,
      outline: 'none',
      zIndex: '22',
      boxShadow: props =>
        props.isOpened ? '0px 0px 1px 2px #B0F2F1 inset' : 'none',
      borderRadius: variables.$input.$radius,
      backgroundColor: props => props.backgroundColor || '#fff',
      '&::after': {
        color: props => props.valueColor,
        display: props => (props.dayNearly ? 'block' : 'none'),
        content: props => `\"${props.dayNearly}\"`,
        transform: 'scale(1)',
        top: '0',
        margin: 'auto',
        bottom: '0',
        height: props => `${variables.$input[`$${props.size}`].$fontSize}px`,
        left: '40px',
        borderBottom: 'none',
        fontSize: props => `${variables.$input[`$${props.size}`].$fontSize}px`,
        lineHeight: props =>
          `${variables.$input[`$${props.size}`].$fontSize}px`,
      },
      '&.Mui-disabled': {
        ...DisableNoneLayer,
      },
      '& input': {
        padding: props =>
          `${variables.$input[`$${props.size}`].$padding}!important`,
        fontSize: props => `${variables.$input[`$${props.size}`].$fontSize}px`,
        height: props => variables.$fieldsSizes[`$${props.size}`],
        lineHeight: props => variables.$fieldsSizes[`$${props.size}`],
        color: props => props.valueColor,
        opacity: props => (props.dayNearly ? '0' : 1),
        '&::-webkit-input-placeholder': {
          color: props => props.placeholderColor,
          opacity: '1 !important',
        },
      },
      '& .MuiInputBase-input': {
        paddingLeft: '40px !important',
      },
      '& .MuiInputAdornment-positionEnd': {
        height: 'auto',
        margin: 'auto',
        top: '0px',
        right: '0px',
        bottom: '0px',
        position: 'absolute',
        paddingRight: '4px',
        '& .arrows-slide-datepicker': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            width: '30px',
            cursor: 'pointer',
            fill: props => props.iconColor,
            '&.left-icon': {
              transform: 'rotate(90deg)',
            },
            '&.right-icon': {
              transform: 'rotate(-90deg)',
            },
            '&.disable-btn': {
              cursor: 'not-allowed',
              opacity: '0.5',
            },
          },
        },
      },
      '& .MuiInputAdornment-positionStart': {
        position: 'absolute',
        left: '0',
        marginLeft: '12px',
        marginRight: '12px',
        marginTop: '0px !important',
        height: 'auto',
        '& button': {
          padding: '0',
          cursor: 'text',
          fontSize: props => variables.$datepicker[`$${props.size}`].$iconSize,
          '& .MuiIconButton-label': {
            color: variables.$datepicker.$iconColor,
          },
        },
      },
    },
  },
}));

const useDialogStyles = makeStyles(() => ({
  root: {
    zIndex: '999999 !important',
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiDialog-container': {
      '& .MuiDialogTitle-root': {
        '& .MuiTypography-h6': {
          fontWeight: 'bold',
          letterSpacing: '-0.5px',
          fontSize: '19px',
        },
      },
      '& .MuiDialogContent-root': {
        lineHeight: '19px',
        margin: '0 2px',
        padding: '8px 20px',
        color: variables.$modalDescColor,
        '&::-webkit-scrollbar': {
          width: '8px',
          backgroundColor: '#EFEFEF',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          background: variables.$helperTextColor,
        },
      },
      '& .MuiDialogActions-root': {
        padding: '8px 24px',
        marginBottom: '16px',
        '&[data-vertical="true"]': {
          flexDirection: 'column',
          alignItems: 'flex-end',
          '& > button:first-child': {
            marginBottom: '15px',
          },
        },
        '& > button': {
          outline: 'none',
          color: variables.$datepicker.$actionsColor,
          fontSize: '13px',
          fontWeight: 'bold',
          background: 'none',
          border: 'none',
          textTransform: 'uppercase',
          cursor: 'pointer',
          '&:disabled': {
            color: '#D8D8D8',
            cursor: 'default',
          },
        },
      },
    },
  },
  paper: {
    minWidth: props => props.minWidth,
    width: props => props.width,
  },
}));

const useRangeSliderStyles = makeStyles(() => ({
  root: {
    marginBottom: props => props.marginBottom,
    display: 'block',
    zIndex: '22',
    height: '6px',
  },
  rail: {
    height: '6px',
    borderRadius: '3px',
    backgroundColor: '#C3CBD5',
  },
  track: {
    borderRadius: '3px',
    backgroundColor: '#B0F2F1',
    height: '6px',
  },
  thumb: {
    height: '22px !important',
    width: '22px !important',
    marginLeft: '-11px',
    backgroundColor: '#B0F2F1',
    ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5) !important'),
    marginTop: '-8px !important',
  },
  disabled: {
    '& .MuiSlider-rail': {
      backgroundColor: '#bdbdbd',
    },
    '& .MuiSlider-track': {
      backgroundColor: '#bdbdbd',
    },
    '& .MuiSlider-thumb': {
      backgroundColor: '#bdbdbd',
    },
  },
}));

const useDropDownPopoverStyles = makeStyles(() => ({
  root: {
    zIndex: props => props.zIndex || '99999 !important',
  },
  paper: {
    margin: props => props.margin,
    transform: 'scale(1) !important',
    ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5)'),
    borderRadius: '2px',
    overflow: props => props.overflow,
    '&.custom-notification-popover': {
      ...MediaQuery.down({
        margin: '20px 0px 0px 0px !important',
        left: '0px !important',
        right: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100% !important',
        maxWidth: '100% !important',
        '@media (hover: hover)': {
          transitionDuration: '0s !important',
          width: 'calc(100% - 15px) !important',
          maxWidth: 'calc(100% - 15px) !important',
        },
        '&::after': {
          display: 'none',
        },
      }).xss,
    },
    '&::after': {
      display: props => props.triangle || 'none',
      position: 'absolute',
      content: '""',
      top: '-8px',
      right: props => props.triangleRight,
      width: '0',
      height: '0',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
    },
  },
}));

const useSwitchStyles = makeStyles(() => ({
  root: {
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#5C9DC2',
    },
  },
  switchBase: {
    color: '#BDBDBD',
  },
  track: {
    backgroundColor: '#4D78B1',
  },
  checked: {
    color: '#B0F2F1 !important',
  },
}));

export {
  useFormControlStyles,
  usePopoverStyles,
  useSelectStyles,
  useAutoCompleteStyles,
  useItemStyles,
  useDatePickerStyles,
  usePickerPaperStyles,
  useDialogStyles,
  useLabelStyles,
  useRangeSliderStyles,
  useDropDownPopoverStyles,
  useSwitchStyles,
};
