import { memo, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import InputWrapper from './InputWrapper';

const SimpleInput = props => {
  const {
    icon,
    size,
    name,
    label,
    type,
    value,
    errors,
    onBlur,
    onFocus,
    onKeyup,
    checked,
    onChange,
    disabled,
    readonly,
    required,
    onCancel,
    onKeydown,
    helperText,
    maxLength,
    onMouseDown,
    passwordEye,
    placeholder,
    registerRef,
    halfSelected,
    withCancelIcon,
    readonlyNoIcon,
    withoutCounter,
    likeMaterialInput,
    withoutRequiredText,
    customStylesInput = {},
    // ...inputProps
  } = props;

  const t = useTranslations();

  const [eyeToggle, setEyeToggle] = useState(false);

  const iconClassName =
    icon?.className ||
    (type === 'password' && passwordEye
      ? `icon-Views toggle-password ${eyeToggle ? 'icon-eye-blocked' : ''}`
      : readonly && !readonlyNoIcon
      ? 'icon-Lock'
      : errors && !passwordEye
      ? 'error-icon icon-Alert'
      : '');

  const textLabel = `${label?.title} ${required ? ' *' : ''}`;
  const text =
    helperText ||
    (required && !withoutRequiredText && t('required_field_text'));

  const isCheckboxOrRadio = type === 'checkbox' || type === 'radio';

  return (
    <InputWrapper {...props} iconClassName={iconClassName}>
      {label && (
        <label
          className={`label-${size}`}
          dangerouslySetInnerHTML={{ __html: textLabel }}
          {...(isCheckboxOrRadio && {
            htmlFor: label?.forId,
          })}
        />
      )}
      <div
        className={clsx('input-element-wrapper', {
          'material-input-wrapper': likeMaterialInput,
        })}
      >
        {icon?.iconLeft && (
          <div onClick={icon?.onClick} className="input-icon input-icon-left">
            <i className={iconClassName} />
          </div>
        )}
        <input
          name={name}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={registerRef}
          onKeyUp={onKeyup}
          autoComplete="off"
          onChange={onChange}
          required={required}
          readOnly={readonly}
          disabled={disabled}
          onKeyDown={onKeydown}
          maxLength={maxLength}
          onMouseDown={onMouseDown}
          style={customStylesInput}
          placeholder={placeholder}
          className={`input-${size}`}
          type={eyeToggle ? 'text' : type}
          {...(isCheckboxOrRadio && {
            checked,
            id: label?.forId,
          })}
        />
        {isCheckboxOrRadio && (
          <span
            className={clsx('checkbox-wrap', {
              'selected-half-part': halfSelected,
            })}
          />
        )}
        {iconClassName && !icon?.iconLeft && (
          <div className="input-icon" onClick={icon?.onClick}>
            <i
              className={iconClassName}
              {...(type === 'password' && {
                onClick: () => setEyeToggle(prev => !prev),
              })}
            />
          </div>
        )}
        {withCancelIcon && (
          <div className="input-icon" onClick={onCancel}>
            <i className="icon-Close" />
          </div>
        )}
      </div>
      {(maxLength || helperText || required) && !withoutCounter && (
        <>
          <div className="bottom-wrap">
            {text && !errors && <p className="helper-text">{text}</p>}
            {maxLength && (
              <span className="counter">
                {value.length}/{maxLength}
              </span>
            )}
          </div>
          {errors && <span className={'error-line'}>{errors}</span>}
        </>
      )}
    </InputWrapper>
  );
};

SimpleInput.defaultProps = {
  size: 'md',
  type: 'text',
  fullWidth: false,
  margin: 0,
  required: false,
  readonly: false,
  hiddenBottomWrapper: false,
  transparentInput: false,
  theme: 'light',
  customStylesInput: {},
  customStylesDiv: {},
  readonlyNoIcon: false,
  className: '',
  value: '',
};

export default memo(SimpleInput);
